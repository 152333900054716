/*
 * Copyright 2018 David Hahn.  All rights reserved.
 *
 * This module contains the web client code for america translated
 */

/// <reference path ="node_modules/@types/jquery/index.d.ts"/>

declare var $:any;


export module AmericaTranslated
{
    /**
     * User data type
     */
    export interface User
    {
        email: string;
        fullname?: string;
        uid?: string;
        rid?: string;
        phone?: string;
        pwhash?: string;
        reset_token?: string;
        active?: boolean;
        verification_key?: string;
    }


    /**
     * User settings.py
     */
    export interface UserSettings
    {
        uid?: string;
        lang: string;
    }


    /**
     * Session data type
     */
    export interface Session
    {
        token?: string;
        uid?: string;
        exp: number;
        refresh?: string;
    }


    /**
     * Document data type
     */
    export interface Document
    {
        title: string;
        language: string;
        body: Array<Array<string>>;
        id: string;
    }


    /**
     * Document node type
     */
    export interface DocumentNode
    {
        type: string;
        id?: string;
        title?: string;
        documents?: Array<DocumentNode>;
    }


    /**
     * Comment data type
     */
    export interface Comment
    {
        cid: string;
        doc_id: string;
        screen_name: string;
        uid: string;
        comment_text: string;
        dt: number;
        up: number;
        dn: number;
    }


    /**
     * The abstract AmericaTranslated API
     */
    export interface AmericaTranslatedApi
    {
        /**
         * Check if we are pointing to the development API
         *
         * @return True if we are pointing to the development API
         */
        isDev(): boolean


        /**
         * Determine if we have a token, whether valid or not
         *
         * @return {boolean} True if we have a token, whether or not it is valid
         */
        hasToken(): boolean;


        /**
         * Remove any token from the local storage
         */
        removeToken(): void;


        /**
         * Call this function to login
         *
         * @param email: {string} The email of the user to login
         * @param password: {string} The password of the user to login
         * @param callback: {Function} The function to callback when finished
         */
        login(email: string, password: string, callback: Function): void;


        /**
         * Call this function to logout
         *
         * @param callback: {function} Function to callback when finished here
         */
        logout(callback: Function): void;


        /**
         * Add a password reset token
         *
         * @param callback: {Function} The callback function
         * @param email: {string} The user's email address whose password needs to be reset
         */
        addPasswordResetToken(callback: Function, email: string): void;


        /**
         * Reset a user password using an emailed token
         *
         * @param callback: {Function} The callback function
         * @param token: {string} The reset token sent by email
         * @param email: {string} The user's email address
         * @param password1: {string} New password
         * @param password2: {string} New password (must match password1)
         */
        resetPassword(callback: Function, token: string, email: string, password1: string, password2: string): void;


        /**
         * Change the current user's password
         *
         * @param callback The callback function
         * @param password0 Current user password
         * @param password1 New user password
         * @param password2 New user password (must match password2)
         */
        changePassword(callback: Function, password0: string, password1: string, password2: string): void;


        /**
         * Create a new user in the system
         *
         * @param callback: {Function} The callback function
         * @param user: {User}: The new user definition
         * @param settings {UserSettings}: The new user settings
         */
        signUp(callback: Function, user: User, settings: UserSettings): void;


        /**
         * Get current user details
         *
         * @param callback The callback function
         */
        readUser(callback: Function): void;


        /**
         * Get current user settings
         *
         * @param callback The callback function
         */
        readSettings(callback: Function): void;


        /**
         * Save the user settings
         *
         * @param callback The callback function
         * @param settings The user settings to save
         */
        saveSettings(callback: Function, settings: UserSettings): void;


        /**
         * Verify a new user account
         *
         * @param callback The callback function
         * @param email Email address on the new user account
         * @param key Verification key for the new user account
         * @param pw1 New password for the account
         * @param pw2 Password confirmation
         */
        verifyUser(callback: Function, email: string, key: string, pw1: string, pw2: string): void;


        /**
         * Retrieve a document from the server
         *
         * @param callback The callback function
         * @param language The language of the document to load
         * @param id The document ID to retrieve
         */
        getDocument(callback: Function, language: string, id: string): void;


        /**
         * Add a new comment to a document
         *
         * @param callback The callback function
         * @param docId The document ID to which the comment applies
         * @param language The language of the comment
         * @param commentText The text of the comment
         */
        addComment(callback: Function, docId: string, language: string, commentText: string): void;


        /**
         * Add a new reply to a comment
         *
         * @param callback The callback function
         * @param commentId The comment ID to which the reply applies
         * @param commentText The text of the reply
         */
        addReply(callback: Function, commentId: string, commentText: string): void;


        /**
         * Get the comments for a specific document and language
         *
         * @param callback The callback function
         * @param docId The document ID for which comments should be retrieved
         * @param language The language of the comments
         */
        getComments(callback: Function, docId: string, language: string): void;


        /**
         * Vote up/down on a comment
         *
         * @param callback The callback function
         * @param commentId The comment ID on which to apply the vote
         * @param direction >0 = Up Vote; <0 = Down Vote; 0 = Error
         */
        voteOnComment(callback: Function, commentId: string, direction: number): void;
    }


    /**
     * The Timecard API implementation
     */
    export class AmericaTranslatedApiImpl implements AmericaTranslatedApi
    {
        /**
         * The API URL
         * @private
         */
        readonly API_URL: string = "https://api.americatranslated.com/v1/req";
        // readonly API_URL: string = "https://api-dev.americatranslated.com/v1/req";


        /**
         * The session key
         * @private
         */
        readonly SESSION_KEY: string = "session";


        /**
         * The token key
         * @private
         */
        readonly TOKEN_KEY: string = "token";


        /**
         * The expire key
         * @private
         */
        readonly EXPIRE_KEY: string = "exp";


        /**
         * The refresh key
         * @private
         */
        readonly REFRESH_KEY: string = "refresh";


        /**
         * The user's current session token
         * @private
         */
        token: string|null = null;


        /**
         * Expiration time of the token
         * @private
         */
        expire: number|null = null;


        /**
         * The user's refresh token
         * @private
         */
        refresh: string|null = null;


        /**
         * The logged in user data
         */



        /**
         * Default constructor for the America Translated API
         */
        constructor()
        {
            this.token = localStorage.getItem(this.TOKEN_KEY);
            this.refresh = localStorage.getItem(this.REFRESH_KEY);
            const expireString = localStorage.getItem(this.EXPIRE_KEY);
            if (expireString !== null)
                this.expire = Number.parseInt(expireString);
        }


        /**
         * Check if we are pointing to the development API
         *
         * @return True if we are pointing to the development API
         */
        isDev(): boolean
        {
            return this.API_URL.indexOf('api-dev') >= 0;
        }


        /**
         *
         * @param request Request data object
         * @param callback1 First function to handle the response (internal to the API)
         * @param callback2 Second function to handle the response (external to the API)
         * @private
         */
        sendRequest(request: object, callback1: any, callback2: any): void
        {
            /* send the POST request with data */
            let requestHandle = $.ajax(
                {
                    type: "POST",
                    url: this.API_URL,
                    data: JSON.stringify(request),
                    contentType: "application/json"
                }
            );

            /* set callback1 if provided */
            if (callback1 !== null)
            {
                requestHandle = requestHandle.always(callback1);
            }

            /* set callback2 if provided */
            if (callback2 !== null)
            {
                requestHandle.then(callback2);
            }
        }


        /**
         * Get an error response for the callback function.  Useful if we detect input
         * errors before sending the request to the server to handle it.
         *
         * @param errors A list of errors to include
         * @return An error response for the callback function
         */
        createErrorResponse(errors: Array<string>): {[key: string]: any}
        {
            errors[errors.length] = 'Ref Id: CLIENT';

            return {
                success: false,
                errors: errors
            }
        }


        /**
         * Add a token to a request
         *
         * @param req: {Object} The request object
         * @private
         */
        addToken(req: any)
        {
            /* load the token from local storage if we do not have it yet */
            if (this.token === null)
            {
                this.token = localStorage.getItem(this.TOKEN_KEY);
            }

            req.token = this.token;
            return req;
        }


        /**
         * Determine if we have a token, whether valid or not
         *
         * @return {boolean} True if we have a token, whether or not it is valid
         */
        hasToken(): boolean
        {
            return (this.token !== undefined && this.token !== null && this.token.length === 44)
        }


        /**
         * Remove any token from the local storage
         */
        removeToken(): void
        {
            localStorage.removeItem(this.TOKEN_KEY);
            localStorage.removeItem(this.EXPIRE_KEY);
            localStorage.removeItem(this.REFRESH_KEY);
        }


        /**
         * Create a new user in the system
         *
         * @param callback: {Function} The callback function
         * @param user: {User}: The new user definition
         * @param settings {UserSettings}: The new user settings
         */
        signUp(callback: Function, user: User, settings: UserSettings): void
        {
            /* build the request object */
            let req: object = {
                action: "Signup",
                data: {
                    email: user.email,
                    name: user.fullname,
                    lang: settings.lang
                }
            };

            /* send the request */
            this.sendRequest(req, undefined, callback);
        }


        /**
         * Call this function to login
         *
         * @param email: {string} The email of the user to login
         * @param password: {string} The password of the user to login
         * @param callback: {Function} The function to callback when finished
         */
        login(email: string, password: string, callback: Function): void
        {
            /* build the request */
            let req: object = {
                action: "Login",
                data: {
                    email: email,
                    password: password
                }
            };

            /* send the request */
            this.sendRequest(req, this.saveSession.bind(this), callback);
        }


        /**
         * Handle the login response
         *
         * @param res: {Object} Response from the server
         * @param res.success: {boolean} Request success flag
         * @param res.data: {Object} Response data
         * @param res.data.token: {string} New user token
         * @param statusText: {string} Either the word "success" or "error"
         * @param status: {Object} Request status object
         * @private
         */
        saveSession(res: any, statusText: string, status: any): void
        {
            /* save token after successful login */
            if (res.ok)
            {
                this.token = res.data.session.token;
                this.expire = res.data.session.exp;
                this.refresh = res.data.session.refresh;
                if (this.token !== null)
                    localStorage.setItem(this.TOKEN_KEY, this.token);
                if (this.expire !== null)
                    localStorage.setItem(this.EXPIRE_KEY, ''+this.expire);
                if (this.refresh !== null)
                    localStorage.setItem(this.REFRESH_KEY, this.refresh);
            }
        }


        /**
         * Call this function to logout
         *
         * @param callback: {function} Function to callback when finished here
         */
        logout(callback: Function): void
        {
            /* build the request */
            let req: object = {
                action: "Logout",
                token: this.token,
                data: {
                    token: this.token
                }
            };

            /* send the request */
            this.sendRequest(req, this.logoutResponse.bind(this), callback);
        }


        /**
         * Handle the logout response
         *
         * @param response: {object} Response from the server
         * @param response.ok: {boolean} Request success flag
         * @param statusText: {string} Either the word "success" or "error"
         * @param status: {Object} Request status object
         * @private
         */
        logoutResponse(response: any, statusText: string, status: any): void
        {
            /* remove token after successful logout */
            if (response.ok)
            {
                localStorage.removeItem(this.TOKEN_KEY);
            }
        }


        /**
         * Add a password reset token
         *
         * @param callback: {Function} The callback function
         * @param email: {string} The user's email address whose password needs to be reset
         */
        addPasswordResetToken(callback: Function, email: string): void
        {
            /* build the request */
            let req: {[key: string]: any} = {
                action: "AddPasswordResetToken",
                data: {
                    email: email
                }
            };

            /* send the request */
            this.sendRequest(req, undefined, callback);
        }


        /**
         * Reset a user password using an emailed token
         *
         * @param callback: {Function} The callback function
         * @param token: {string} The reset token sent by email
         * @param email: {string} The user's email address
         * @param password1: {string} New password
         * @param password2: {string} New password (must match password1)
         */
        resetPassword(callback: Function, token: string, email: string, password1: string, password2: string): void
        {
            /* build the request */
            let req: {[key: string]: any} = {
                action: "ResetPassword",
                data: {
                    token: token,
                    email: email,
                    password1: password1,
                    password2: password2
                }
            };

            /* send the request */
            this.sendRequest(req, undefined, callback);
        }


        /**
         * Change the current user's password
         *
         * @param callback The callback function
         * @param password0 Current user password
         * @param password1 New user password
         * @param password2 New user password (must match password2)
         */
        changePassword(callback: Function, password0: string, password1: string, password2: string): void
        {
            /* validate the request */
            if (password1 !== password2)
            {
                let res = this.createErrorResponse([
                    "Passwords do not match"
                ]);
                callback(res, "success", {});
                return;
            }

            /* build the request */
            let req: {[key: string]: any} = {
                action: "ChangePassword",
                data: {
                    password0: password0,
                    password1: password1,
                    password2: password2
                }
            };

            /* add the token */
            req = this.addToken(req);

            /* send the request */
            this.sendRequest(req, undefined, callback);
        }


        /**
         * Get current user details
         *
         * @param callback The callback function
         */
        readUser(callback: Function): void
        {
            /* build the request */
            let req: {[key: string]: any} = {
                action: "ReadUser",
                data: {
                    token: this.token
                }
            };

            /* add the token */
            req = this.addToken(req);

            /* send the request */
            this.sendRequest(req, undefined, callback);
        }


        /**
         * Get current user settings
         *
         * @param callback The callback function
         */
        readSettings(callback: Function): void
        {
            /* build the request */
            let req: {[key: string]: any} = {
                action: "ReadSettings",
                data: {
                    token: this.token
                }
            };

            /* add the token */
            req = this.addToken(req);

            /* send the request */
            this.sendRequest(req, undefined, callback);
        }


        /**
         * Save the user settings
         *
         * @param callback The callback function
         * @param settings The user settings to save
         */
        saveSettings(callback: Function, settings: UserSettings): void
        {
            /* build the request */
            let req: {[key: string]: any} = {
                action: "SaveSettings",
                data: {
                    settings: settings
                }
            };

            /* add the token */
            req = this.addToken(req);

            /* send the request */
            this.sendRequest(req, undefined, callback);
        }


        /**
         * Verify a new user account
         *
         * @param callback The callback function
         * @param email Email address on the new user account
         * @param key Verification key for the new user account
         * @param pw1 New password for the account
         * @param pw2 Password confirmation
         */
        verifyUser(callback: Function, email: string, key: string, pw1: string, pw2: string): void
        {
            /* build the request */
            let req: {[key: string]: any} = {
                action: 'VerifyUser',
                data: {
                    'email': email,
                    'verification_key': key,
                    'password1': pw1,
                    'password2': pw2
                }
            };

            /* send the request */
            this.sendRequest(req, this.saveSession.bind(this), callback);
        }


        /**
         * Retrieve a document from the server
         *
         * @param callback The callback function
         * @param language The language of the document to load
         * @param id The document ID to retrieve
         */
        getDocument(callback: Function, language: string, id: string): void
        {
            /* build the request */
            let req: {[key: string]: any} = {
                action: 'GetDocument',
                data: {
                    'language': language,
                    'doc_id': id
                }
            };

            /* send the request */
            this.sendRequest(req, undefined, callback);
        }


        /**
         * Add a new comment to a document
         *
         * @param callback The callback function
         * @param docId The document ID to which the comment applies
         * @param language The language of the comment
         * @param commentText The text of the comment
         */
        addComment(callback: Function, docId: string, language: string, commentText: string): void
        {
            /* build the request */
            let req: {[key: string]: any} = {
                action: 'AddComment',
                data: {
                    'lang': language,
                    'doc_id': docId,
                    'comment_text': commentText
                }
            };

            /* add the token */
            req = this.addToken(req);

            /* send the request */
            this.sendRequest(req, undefined, callback);
        }


        /**
         * Add a new reply to a comment
         *
         * @param callback The callback function
         * @param commentId The comment ID to which the reply applies
         * @param commentText The text of the reply
         */
        addReply(callback: Function, commentId: string, commentText: string): void
        {
            /* build the request */
            let req: {[key: string]: any} = {
                action: 'AddReply',
                data: {
                    'cid': commentId,
                    'comment_text': commentText
                }
            };

            /* add the token */
            req = this.addToken(req);

            /* send the request */
            this.sendRequest(req, undefined, callback);
        }


        /**
         * Get the comments for a specific document and language
         *
         * @param callback The callback function
         * @param docId The document ID for which comments should be retrieved
         * @param language The language of the comments
         */
        getComments(callback: Function, docId: string, language: string): void
        {
            /* build the request */
            let req: {[key: string]: any} = {
                action: 'GetComments',
                data: {
                    'doc_id': docId,
                    'lang': language
                }
            };

            /* send the request */
            this.sendRequest(req, undefined, callback);
        }


        /**
         * Vote up/down on a comment
         *
         * @param callback The callback function
         * @param commentId The comment ID on which to apply the vote
         * @param direction >0 = Up Vote; <0 = Down Vote; 0 = Error
         */
        voteOnComment(callback: Function, commentId: string, direction: number): void
        {
            /* build the request */
            let req: {[key: string]: any} = {
                action: 'Vote',
                data: {
                    'cid': commentId,
                    'dir': direction
                }
            };

            /* add the token */
            req = this.addToken(req);

            /* send the request */
            this.sendRequest(req, undefined, callback);
        }
    }
}
